import React from "react"
import {
  Layout,
  Books,
  Hero,
  Scroll,
  // Magazines
} from "../components/"
import { graphql } from "gatsby"

const HomePage = ({ data }) => {
  const {
    contentfulBooks: { nodes: books },
  } = data

  // const {
  //   contentfulMagazines: { nodes: mags },
  // } = data
  // console.log(books)

  // console.log("books", books)

  return (
    <Layout>
      <Hero />
      <Books books={books} title="Könyvek" link="translation" />
      {/* <Magazines
        mags={mags}
        title="Legújabb fordítások (magazin)"
        link="translation"
      /> */}
      <Scroll
        // showBelow={1500}
        css="
        position: fixed; 
        right: 5rem; 
        bottom: 6rem; 
        font-size: 1.7rem;
        "
      />
    </Layout>
  )
}

export const query = graphql`
  {
    contentfulBooks: allContentfulBook(
      filter: { author: { authorFullName: { ne: "Pék Zoltán" } } }
      limit: 20
      sort: { fields: [bookYear___bookYear, createdAt], order: [DESC, DESC] }
    ) {
      nodes {
        contentful_id
        bookTitle
        author {
          authorFullName
        }
        publisher {
          publisherName
        }
        bookYear {
          bookYear
        }

        bookSize
        bookImage {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: TRACED_SVG)
        }
        bookText {
          childMarkdownRemark {
            excerpt(format: HTML, pruneLength: 100)
          }
        }
      }
    }

    contentfulMagazines: allContentfulMagazine(
      filter: { author: { authorFullName: { ne: "Pék Zoltán" } } }
      limit: 10
      sort: { fields: createdAt, order: DESC }
    ) {
      nodes {
        contentful_id
        textTitle
        author {
          authorFullName
        }
        magazineName
        publisher {
          publisherName
        }
        magazinePublished(formatString: "YYYY-MM")
        textSize
        magazineImage {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: TRACED_SVG)
        }
      }
    }
  }
`

export default HomePage
